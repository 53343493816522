import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import carne1 from "../../images/carne1.jpeg";
import carne2 from "../../images/carne2.jpeg";
import carne3 from "../../images/carne3.jpeg";

const Container = tw.div`relative py-5 lg:py-24 mx-auto`;

const SingleColumn = tw.div` `;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
const HighlightedText = tw.span`bg-red-800 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-16 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded-lg shadow-lg border-2 md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-80 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold font-black`;
const Description = tw.p`mt-2 text-base font-semibold leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-red-800 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

export default () => {
    const cards = [
        {
            imageSrc: carne1,
            title: "Ganado Bovino en Pie",
            description:
                "Cría de ganado de razas cebuínas, alimentado en pasturas naturales evitando el uso de concentrados, hormonas u otros químicos. La carne de nuestros animales criados en pasturas naturales “Grass Fed”, nos permite ofrecer un producto de alta calidad, con niveles significativamente más altos en ácidos grasos omega-3, antioxidantes, minerales y otros nutrientes importantes.",
        },
        {
            imageSrc: carne2,
            title: "Ganado Bovino en Canal",
            description:
                "El transporte en canal garantiza la durabilidad del producto y evita modificaciones en el PH y en el color de la carne.",
        },

        {
            imageSrc: carne3,
            title: "Ganado Bovino Deshuesado empacado al vacío",
            description:
                "Con las piezas de carne empacadas al vacío se obtiene más sabor, color, frescura, textura y lo más importante, una pérdida mínima de los nutrientes. Además se atiende la necesidad particular de cada cliente.",
        }
    ];

    return (
        <>
             <Container id="Productos">
                <HeadingInfoContainer>
                    <HeadingTitle>Productos de calidad <HighlightedText> Triple A</HighlightedText></HeadingTitle>
                </HeadingInfoContainer>
                {cards.map((card, i) => (
                    <Card key={i} reversed={i % 2 === 1}>
                        <Image imageSrc={card.imageSrc} />
                        <Details>
                            <Subtitle>{card.subtitle}</Subtitle>
                            <Title>{card.title}</Title>
                            <Description>{card.description}</Description>
                            {/* <Link href={card.url}>See Event Details</Link> */}
                        </Details>
                    </Card>
                ))}
            </Container>
        </>
    );
};
