import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading } from "../misc/Headings.js";
import { css } from "styled-components/macro"; //eslint-disable-line

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-2 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-6xl text-base md:text-2xl`;

const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const HighlightedText = tw.span`bg-red-800 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-red-800 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-red-800 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-red-800 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-red-800 fill-current w-24`;

export default () => {
    return (
        <Container id="Nosotros">
            <SingleColumn>
                <HeadingInfoContainer>
                    <Heading>
                        <>
                            Nuestra <HighlightedText> Historia</HighlightedText>
                        </>
                    </Heading>
                    <HeadingDescription>
                        Somos una empresa del departamento del Caquetá con una trayectoria familiar en la cría, ceba y comercialización de ganado bovino. Esta experiencia en el mercado nos permite ofrecer un producto de excelente calidad. <strong tw="text-red-800">Nuestros principales objetivos son la conservación del medio ambiente, el bienestar animal y la producción de un alimento de alta calidad.</strong>
                    </HeadingDescription>
                </HeadingInfoContainer>
            </SingleColumn>
            <SvgDotPattern2 />
        </Container>
    );
};
