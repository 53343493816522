import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithVideo.js";
import Nosotros from "components/sections/nosotros.js";
import Productos from "components/sections/productos.js";
import Fincas from "components/sections/fincas.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import cows from "images/cows.png";

export default () => {
  const HighlightedText = tw.span`bg-red-800 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Las mejores carnes <HighlightedText>Para tu negocio.</HighlightedText></>}
        description="Cuidamos nuestro producto desde su origen hasta el cliente final, obteniendo una carne saludable con un magnífico sabor y terneza."
        imageSrc={cows}
        imageCss={tw`rounded-3xl`}
        imageDecoratorBlob={true}
        primaryButtonText="Contáctanos"
        primaryButtonUrl="#Contacto"
        watchVideoButtonText="Conoce Nuestro Ganado"
      />
      <Nosotros />
      <Productos />
      <Fincas />
      <Footer />
    </AnimationRevealPage>
  );
}
