import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import ganado from "../../images/ganado.jpeg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto  py-2 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 mb-4 text-center md:text-left text-base md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const HighlightedText = tw.span`bg-red-800 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-red-800 -z-10`
]);

const imgCss = Object.assign(tw`bg-cover`, tw`rounded-3xl`);

export default ({
    heading = (
        <>
            Las mejores Fincas Ganaderas
        </>
    ),
    description = "Nuestros productos se derivan de la cría de ganado en pasturas naturales del campo caqueteño, donde los animales gozan de bienestar y armonía con el medio ambiente.  Las buenas prácticas de pastoreo nos permiten conservar nuestros bosques, árboles y ríos de nuestras fincas garantizando la sostenibilidad ambiental y un producto de excelente calidad.",
    imageSrc = ganado,
    imageCss = imgCss,
    imageContainerCss = tw`md:w-1/2 h-auto`,
    imageInsideDiv = false,
    textOnLeft = true
}) => {
    return (
        <Container id="Fincas">
            <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Heading>{heading}</Heading>
                        <Description>{description}</Description>
                        {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
                    </TextContent>
                </TextColumn>
                <ImageColumn css={imageContainerCss}>
                    {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
                </ImageColumn>
            </TwoColumn>
        </Container>
    );
};
