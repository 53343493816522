import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logoBlanco.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";


const Container = tw(ContainerBase)`bg-red-800 text-white -mx-8 -mb-8 mt-12`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-12`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-40`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-0 text-center`;

const SocialLinksContainer = tw.div`mt-5`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-8 h-8`}
  }
`;

const CopyrightText = tw.p`text-center mt-12 font-medium tracking-wide text-sm text-white`

const DevelopText = tw.p`text-center mt-12 font-medium tracking-wide text-sm text-white`

export default () => {
  return (
    <Container id="Contacto">
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>
          <LinksContainer>
            <Link href="tel:3112680466"  style={{marginRight: 10}}>
              Llamanos
              <br></br>
              <strong>3112680466</strong>
            </Link>
            <Link href="https://wa.me/573175681896" target={'_blank'} style={{marginLeft: 10}}>
              Whatsapp
              <br></br>
              <strong>3175681896</strong>
            </Link>
          </LinksContainer>
          <CopyrightText>
            &copy; GRUPO EMPRESARIAL TRIPLE A ZOMAC SAS.
            <br></br>
            Carrera 2 Bis No. 33-27C 33A Urbanización Yapurá Norte, Florencia, Caquetá
            <br></br>
            grupoempresarialtriplea@gmail.com
          </CopyrightText>
        </Row>
        <Row>
          <DevelopText>
            Desarrollado por: <Link href="https://devcloud.com.co" target="_blank">DevCloud - Desarrollo de Software</Link>
          </DevelopText>
        </Row>
      </Content>
    </Container>
  );
};
